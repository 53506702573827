import { render, staticRenderFns } from "./DK.vue?vue&type=template&id=3915d110&scoped=true&"
import script from "./DK.vue?vue&type=script&lang=js&"
export * from "./DK.vue?vue&type=script&lang=js&"
import style0 from "./DK.vue?vue&type=style&index=0&id=3915d110&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3915d110",
  null
  
)

export default component.exports